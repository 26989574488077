import { mentionMeFlag, marketDetails, anonymousIdHeader } from '#constants'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import { getCountryFromLocale } from '#src/common/lib/locale'
import { parseCookies } from 'nookies'
import { getLaunchDarklyFlag } from '#api/flags'
import { getMarketFromCtx } from '#lib/get-market'

export const showReferFriend = locale =>
  locale !== marketDetails.FR.locale && locale !== marketDetails.US.locale

export const getReferFriend = async ctx => {
  const cookies = parseCookies(ctx)
  const market = getMarketFromCtx(ctx)
  const enabled = await getLaunchDarklyFlag(
    mentionMeFlag,
    cookies[anonymousIdHeader],
    market?.id
  )
  return {
    enabled,
    host: process.env.MENTION_ME_TAG
  }
}

export const getReferFriendFromClientSite = async locale => {
  let country = getCountryFromLocale(locale)
  country = country === 'GB' ? 'UK' : country
  const enabled = await clientSideGetLaunchDarklyFlag(
    mentionMeFlag,
    null,
    country
  )
  return { enabled }
}
