import PropTypes from 'prop-types'
import { richText, alternateCallToActionProps } from '@proptypes'
import {
  featureTemplates,
  Select,
  Button
} from '@pretamanger/component-library'
import {
  DropdownContainer,
  ComparisonTitle,
  ButtonContainer,
  ResultContainer,
  Text,
  TextContainer,
  Separator,
  BannerText,
  BannerTextWrapper,
  FillerWrapper,
  ButtonWrapper,
  CustomizeButton,
  CustomizeLink
} from './styles'
import {
  parseProductOptions,
  parseQtyOptions,
  initialSavingsValue,
  generateCta,
  getSavingsText,
  parseTextField,
  getCalculationResults,
  getFormattedPrice
} from './util'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { translate } from '../../translation'
import { listUserToBeSubscribe } from '#src/common/constants'

export const SubscriptionCalculator = ({
  isClubPretFlow,
  title,
  icon,
  description,
  actions,
  planType,
  comparisonTitle,
  disclaimer,
  products,
  actionsTitle,
  actionsTitleAlternate,
  subscriptionPlanPrice,
  messageClubPret,
  ...otherProps
}) => {
  const Template = featureTemplates.featureItem['subscription-calculator']
  const { locale, query } = useRouter()
  const drinkOptions = parseProductOptions(products, locale)
  const qtyOptions = parseQtyOptions(locale)
  const callToActions = generateCta(actions)
  const [selectValues, setSelectedValues] = useState({
    product: { id: 0 },
    qty: { id: 0 }
  })
  const [originURL, setOriginURL] = useState('')
  const [calculationResults, setSavings] = useState(initialSavingsValue)
  const [activeSectionIndex, setActiveSection] = useState(0)
  const updateValues = (key, id) => {
    let newVal = selectValues
    newVal[key] = key === 'product' ? drinkOptions[id] : qtyOptions[id]
    setSelectedValues({ ...newVal })
  }
  const showCTASubscribe =
    query?.userType &&
    listUserToBeSubscribe.includes(query?.userType?.toLowerCase())

  useEffect(() => {
    const product = selectValues.product
    const qty = selectValues.qty
    if (product.price >= 0 && qty.value >= 0) {
      const isReset = product.price === 0 || qty.value === 0
      if (isReset) {
        setSavings({ ...initialSavingsValue })
      } else {
        const calculationResults = getCalculationResults(
          product,
          qty,
          subscriptionPlanPrice
        )
        setSavings({ ...calculationResults })
      }
    }
  }, [selectValues.product, selectValues.qty, subscriptionPlanPrice])

  useEffect(() => {
    const basePath = window.location.origin + '/' + locale
    setOriginURL(basePath)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const savings = calculationResults.savings[activeSectionIndex]

  return (
    <>
      <div id={`calculator${planType}`} />
      <Template
        actions={callToActions}
        body={parseTextField(description)}
        bodyAlignment={'left'}
        title={title}
        icon={icon}
        planType={planType}
        disclaimerText={disclaimer}
        actionsTitle={savings < 0 ? actionsTitleAlternate : actionsTitle}
        {...otherProps}
      >
        <DropdownContainer>
          <Select
            id={`drinkSelector${planType}`}
            options={drinkOptions}
            current={selectValues.product.label}
            onChange={id => {
              updateValues('product', id)
            }}
            key={selectValues.product.label}
            isReturnId
          />
          <Select
            id={`qtySelector${planType}`}
            options={qtyOptions}
            current={selectValues.qty.label}
            onChange={id => {
              updateValues('qty', id)
            }}
            key={selectValues.qty.label}
            isReturnId
          />
        </DropdownContainer>
        <ComparisonTitle level='h4'>
          {`${
            translate('cs.calculator.price.comparison.title', locale)?.value
          }`}
        </ComparisonTitle>
        <ButtonContainer>
          <Button
            id={'perWeekBtn'}
            onClick={() => {
              setActiveSection(0)
            }}
            type='submit'
            styleType='quinary'
            isActive={activeSectionIndex === 0}
          >
            {`${translate('cs.calculator.perweek.text', locale)?.value}`}
          </Button>
          <Button
            id={'perMthBtn'}
            onClick={() => {
              setActiveSection(1)
            }}
            type='submit'
            styleType='quinary'
            isActive={activeSectionIndex === 1}
          >
            {`${translate('cs.calculator.permonth.text', locale)?.value}`}
          </Button>
          <Button
            id={'perYrBtn'}
            onClick={() => {
              setActiveSection(2)
            }}
            type='submit'
            styleType='quinary'
            isActive={activeSectionIndex === 2}
          >
            {`${translate('cs.calculator.peryear.text', locale)?.value}`}
          </Button>
        </ButtonContainer>
        {savings >= 0 && (
          <ResultContainer>
            <TextContainer>
              <Text>{`${
                translate('cs.calculator.withsubs.text', locale)?.value
              }`}</Text>
              <Text>
                {getFormattedPrice(
                  calculationResults.withCs,
                  activeSectionIndex,
                  locale,
                  isClubPretFlow
                )}
              </Text>
            </TextContainer>
            <Separator />
            <TextContainer>
              <Text>{`${
                translate('cs.calculator.withoutsubs.text', locale)?.value
              }`}</Text>
              <Text>
                {getFormattedPrice(
                  calculationResults.normal,
                  activeSectionIndex,
                  locale,
                  isClubPretFlow
                )}
              </Text>
            </TextContainer>
          </ResultContainer>
        )}
        <BannerTextWrapper
          withMarginBottom={!messageClubPret}
          isClubPretFlow={isClubPretFlow}
        >
          <BannerText isClubPretFlow={isClubPretFlow}>
            {getSavingsText(
              savings,
              activeSectionIndex,
              locale,
              !!messageClubPret
            )}
          </BannerText>
        </BannerTextWrapper>
        {messageClubPret && (
          <BannerTextWrapper
            isPromo
            withMarginBottom
            data-testid='banner-promo-club-pret-calculator'
          >
            <BannerText isPromo>{messageClubPret}</BannerText>
          </BannerTextWrapper>
        )}
        {isClubPretFlow && showCTASubscribe && (
          <ButtonWrapper data-testid='button-subscribe-club-pret-calculator'>
            <CustomizeLink
              href={`${originURL}/order`}
              target='_blank'
              passHref
              rel='noreferrer'
            >
              <CustomizeButton>
                {`${
                  translate('club.pret.calculator.cta.label', locale)?.value
                }`}
              </CustomizeButton>
            </CustomizeLink>
          </ButtonWrapper>
        )}
        <FillerWrapper
          style={{
            visibility: 'hidden',
            display: savings >= 0 ? 'none' : 'block'
          }}
        />
      </Template>
    </>
  )
}

SubscriptionCalculator.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(alternateCallToActionProps)),
  description: richText.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  planType: PropTypes.string,
  comparisonTitle: PropTypes.string,
  disclaimer: PropTypes.string,
  products: PropTypes.array.isRequired,
  actionsTitle: PropTypes.string,
  actionsTitleAlternate: PropTypes.string,
  subscriptionPlanPrice: PropTypes.number,
  messageClubPret: PropTypes.string,
  isClubPretFlow: PropTypes.bool
}
