import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Heading } from '@pretamanger/component-library'
export const ContentWrapper = styled('div')`
  ${tw`mb-24 lg:pt-8`}
`

export const ContentTitle = styled(Heading)`
  ${tw`mb-4 font-normal hidden md:block`}
`

export const CategoryCardsContainer = styled.div`
  ${tw`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 md:gap-4 lg:gap-3 xl:gap-4 px-0 sm:pb-0 mx-0`}
`
