import { ReactNode } from 'react'
import {
  Feature as FeatureComponent,
  FeatureHero,
  getScreenSizes
} from '@pretamanger/component-library'
import { RichText } from '../../content'
import { Action } from '../../call-to-action'
import { StrapLine } from '#src/common/components/content/markdown/strap-line'
import SocialNetworks from '../../social-networks'
import {
  SocialNetworksContainer,
  SOCIAL_ICONS_ALIGNMENTS,
  StrapLineContainer
} from './styles'
import { getImage } from '#src/common/components/image/get-image'
import { getMobileImage } from '#src/common/components/image/get-mobileimage'
import useMedia from 'react-use/lib/useMedia'

type ImgSources = {
  -readonly [key in keyof typeof MAIN_IMAGE_SOURCES]: string
}

export type ImageType = {
  alt: string
  height: number
  src: string
  width: number
  sources: ImgSources
}

type SocialNetworksType = {
  name: string
  link: string
}[]

export const MAIN_IMAGE_SOURCES = {
  xs: 1200,
  sm: 1400,
  md: 2000,
  lg: 2500,
  xl: 2500
} as const

const HEADER_IMAGE_SOURCES = { xs: 148, sm: 148, md: 148, lg: 182, xl: 182 }

const Feature = ({
  actions,
  body,
  colour,
  displaySocialLinks,
  headerImage,
  headingLevel,
  image,
  socialNetworks,
  strapline,
  style,
  title
}: {
  actions: any[]
  body: string
  colour: 'grey' | 'orange' | 'transparent'
  displaySocialLinks: boolean
  headerImage: ImageType
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'intro'
  image: ImageType
  socialNetworks: SocialNetworksType
  strapline?: string
  style: keyof typeof SOCIAL_ICONS_ALIGNMENTS
  title: string
}): JSX.Element | ReactNode => {
  const callToActions = [
    actions.map(action => <Action key={action.id} {...action} />)
  ]

  const RT = RichText as (props: { text: string }) => JSX.Element

  const content = (
    <>
      <RT text={body} />
      {strapline && (
        <StrapLineContainer>
          <StrapLine content={strapline} />
        </StrapLineContainer>
      )}
      {displaySocialLinks && socialNetworks.length > 0 && (
        <SocialNetworksContainer featureStyle={style}>
          <SocialNetworks socialNetworks={socialNetworks} />
        </SocialNetworksContainer>
      )}
    </>
  )

  const isMobileView = useMedia(`(max-width: ${getScreenSizes().md - 1}px)`)

  if (style === 'hero') {
    return (
      <FeatureHero
        title={title}
        image={
          image &&
          (isMobileView && image.sources
            ? getMobileImage({
                img: {
                  ...image,
                  alt: title
                },
                imageWidths: MAIN_IMAGE_SOURCES,
                defaultWidth: 2000
              })
            : getImage({
                img: {
                  ...image,
                  alt: title
                },
                imageWidths: MAIN_IMAGE_SOURCES,
                defaultWidth: 2000
              }))
        }
        actions={callToActions}
      >
        {content}
      </FeatureHero>
    )
  }

  return (
    <FeatureComponent
      title={title}
      colour={colour}
      style={style}
      headingLevel={headingLevel}
      headerImage={
        headerImage &&
        getImage({
          img: {
            ...headerImage,
            alt: title
          },
          imageWidths: HEADER_IMAGE_SOURCES
        })
      }
      image={
        image &&
        getImage({
          img: {
            ...image,
            alt: title
          },
          imageWidths: MAIN_IMAGE_SOURCES
        })
      }
      actions={callToActions}
    >
      {content}
    </FeatureComponent>
  )
}

Feature.defaultProps = {
  actions: [],
  colour: 'transparent',
  displaySocialLinks: false,
  socialNetworks: []
}

export default Feature
