import { Image } from '@pretamanger/component-library'

type Img = {
  sources: {
    xs: string
  }
  alt?: string
}

type ImageWidths = {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

type ImageSources = {
  xs?: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
}

const IMAGE_WIDTHS = {
  xs: 800,
  sm: 800,
  md: 800,
  lg: 800,
  xl: 800
}

type GetImageArgs = {
  img: Img
  imageWidths?: ImageWidths
  fm?: string
  defaultWidth?: number
  ariaHidden?: boolean
}

export const getMobileImage = ({
  img,
  imageWidths = IMAGE_WIDTHS,
  fm = 'jpg',
  defaultWidth = 264,
  ariaHidden
}: GetImageArgs) => {
  const _getImage = width =>
    `${img.sources.xs}?fm=${fm}${
      fm !== 'webp' ? '&fl=progressive' : ''
    }&w=${width}`

  const imageSources: ImageSources = Object.entries(
    imageWidths
  ).reduce<ImageSources>(
    (sources, [size, width]) => ({
      ...sources,
      [size]: _getImage(width)
    }),
    {}
  )

  return (
    <Image
      sources={imageSources}
      img={_getImage(defaultWidth)}
      alt={img.alt ?? ''}
      {...(ariaHidden && { 'aria-hidden': true })}
    />
  )
}
