import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Container = styled('div')`
  ${tw`content-center`}
`

export const ImageContainer = styled('div')`
  ${tw`content-center w-[8%] m-[auto] mb-[10px]`};
`
