import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Feature, Heading } from '@pretamanger/component-library'

export const FeatureComponent = styled(Feature)`
  ${tw`mt-16`}
`

export const ActionContainer = styled('div')`
  ${tw`flex flex-wrap -m-2`}
`

export const ActionWrapper = styled('div')`
  ${tw`flex flex-grow p-2 w-full md:w-1/3`}
  > * {
    ${tw`w-full`}
  }
`
export const ContentWrapper = styled('div')`
  ${tw`w-full pt-20`}
`
export const HeadingComponent = styled(Heading)`
  ${tw`px-6 pb-6 md:text-center`}
`
