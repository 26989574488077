import platformClient from '#lib/platform-client'
import { localeHeader } from '#constants'
import httpClient from '#lib/http-client'
import { encodeURIComponent } from 'encodeuri-safe'

export const getListProducts = async (skus, locale) => {
  const results = await platformClient(`/products/by-skus/${skus.join(',')}`, {
    headers: {
      [localeHeader]: locale
    }
  })
  return results
}

export const fetchPageBySlug = async (slug, locale) => {
  const host = process.env.CONTENTFUL_PLATFORM_API_HOST
  const response = await httpClient(
    `${host}/v1/content/website/pages/${encodeURIComponent(
      slug
    ).toLowerCase()}`,
    {
      headers: { [localeHeader]: locale }
    }
  )
  if (!response.ok) {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
  return response.json()
}

export const fetchAllPages = async locale => {
  const host = process.env.CONTENTFUL_PLATFORM_API_HOST
  const response = await httpClient(`${host}/v1/content/website/pages`, {
    headers: { [localeHeader]: locale }
  })
  if (!response.ok) {
    const error = new Error(response.statusText)
    error.response = response
    // eslint-disable-next-line no-console
    console.log('fetchAllPages', {
      locale,
      response,
      error,
      statusText: response.statusText
    })
    throw error
  }
  const result = await response.json()
  return result.pages
}
