export const mapActionForCategoryCard = action => {
  return {
    ...action,
    link: {
      href: action?.slug
    },
    image: {
      src: action?.image?.fields?.file?.url
    }
  }
}
