import { useState, useRef, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { formValueProps } from '@proptypes'
import {
  Button,
  Dropdown,
  Input,
  TextArea
} from '@pretamanger/component-library'
import { mapLocale } from '../../lib/locale'
import Translation from '../translation'
import InfoPanel from '../info-panel'
import { FormWrapper, Row, ZeroBagTermsAndConditions, Field } from './styles'
import { validateForm } from '../../lib/api/form'
import { useProfile } from '#src/common/hooks/useProfile'
import { getRole } from './utils'
import { removeUndefinedProperties } from '#src/common/lib/object'
import { employeeHelpOption, marketDetails } from '#src/common/constants'
import { USER_ROLES } from '#src/loyalty/util'
import { LoadingOverlay } from '#src/common/components/loading-overlay'
import { provideAnonymousId } from '#src/common/lib/provide-anonymous-id'
import { useAsync } from 'react-use'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import { egcContactUsFlag } from '#constants'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'

const CmsForm = ({
  submitHandler,
  initialState,
  isBagZeroValue,
  formId,
  formLabel
}) => {
  const formRef = useRef(null)
  const { locale, query } = useRouter()
  const mappedLocale = mapLocale(locale, true)
  const market = useMemo(() => {
    return getMarketDetailsFromLocale(locale)
  }, [locale])
  const anonymousId = provideAnonymousId()

  const [formState, setFormState] = useState(initialState)
  const [formValid, setFormValid] = useState(true)
  const [formSuccess, setFormSuccess] = useState(false)
  const [egcContactUsEnabledFlag, setEgcContactUsEnabledFlag] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [errors] = useState({})
  const [formValues, setFormValues] = useState({})

  const userProfile = useProfile()

  const FORM = 'contact-us'

  const FIRST_NAME = 'first-name'
  const LAST_NAME = 'last-name'
  const EMAIL = 'email-address'
  const ROLE = 'role'
  const PHONE = 'telephone-number'
  const HELP = 'help'
  const COMMENTS = 'comments'
  const WHICH_SHOP = 'which-shop'

  const role = getRole(userProfile?.userRole, query?.user_role)
  const isEmployee = role === USER_ROLES.EMPLOYEE
  const isUK = locale === marketDetails.UK.locale
  const egcContactUsEnabled = isUK && isEmployee && egcContactUsEnabledFlag

  const fieldsFormDefinition = [
    { id: FIRST_NAME, mapTo: 'FirstName', type: ['isNotEmpty'] },
    { id: LAST_NAME, mapTo: 'Surname', type: ['isNotEmpty'] },
    { id: EMAIL, mapTo: 'EmailAddress', type: ['isNotEmpty', 'isEmail'] },
    { id: PHONE, mapTo: 'Telephone', type: ['isNotEmpty', 'isNumeric'] },
    { id: HELP, mapTo: 'FeedbackType', type: ['isNotEmpty'] },
    { id: COMMENTS, mapTo: 'Comments' },
    { id: WHICH_SHOP, mapTo: 'ShopNumber' },
    { id: ROLE, mapTo: 'Role' }
  ]

  const formDefinition = {
    id: FORM,
    fields: fieldsFormDefinition
  }
  const EMAIL_REGEX_PATTERN = new RegExp(/^\S+@\S+\.\S+$/g)
  const TELEPHONE_REGEX_PATTERN = new RegExp(/^[+]{0,1}(0|[1-9][0-9]*)$/g)

  useEffect(() => {
    if (userProfile?.email && egcContactUsEnabled) {
      setFormValues(prev => ({
        ...prev,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        emailAddress: userProfile.email
      }))
    }
  }, [
    userProfile?.email,
    userProfile?.firstName,
    userProfile?.lastName,
    egcContactUsEnabled
  ])

  useAsync(async () => {
    const value = await clientSideGetLaunchDarklyFlag(
      egcContactUsFlag,
      anonymousId,
      market?.id
    )
    setEgcContactUsEnabledFlag(value)
  })

  const onSubmit = async e => {
    e.preventDefault()

    const fieldArray = []
    const rows = Array.from(e.target.children)
    rows.forEach(row => {
      fieldArray.push(
        ...(row.querySelectorAll('input, textarea, select') || [])
      )
    })

    const formValues = fieldArray.map(f => {
      const { id, name, value, options, checked, selectedIndex } = f
      let item
      if (id) {
        item = { id, value, name }
      }
      if (options) {
        item.description = options[selectedIndex]?.text
      }
      if (checked) {
        item.value = true
      }
      return item
    })

    if (egcContactUsEnabled) {
      formValues.push({
        id: HELP,
        name: 'help',
        value: '5',
        description: employeeHelpOption
      })
    }

    var formValuesObj = formValues.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.value }),
      {}
    )

    const { formState, formValid } = validateForm(formDefinition, formValues)
    setFormState(formState)
    setFormValues(formValuesObj)
    setFormValid(formValid)

    if (formValid) {
      setFormSubmitting(true)
      const result = await submitHandler(
        formDefinition,
        formValues,
        mappedLocale
      )
      if (result) {
        setFormSubmitting(false)
        if (result.errors) {
          setFormState(result.errors)
          setFormValid(false)
        } else {
          setFormSuccess(true)
        }
      }
    }
    formRef.current.focus()
  }

  const formErrors = values => {
    if (!values.firstName) {
      errors['firstName'] = formLabel.fields.firstNameValidationMessage
    } else {
      errors['firstName'] = ''
    }

    if (!values.lastName) {
      errors['lastName'] = formLabel.fields.lastNameValidationMessage
    } else {
      errors['lastName'] = ''
    }

    if (
      values.emailAddress.length === 0 ||
      values.emailAddress.match(EMAIL_REGEX_PATTERN) === null
    ) {
      errors['emailAddress'] = formLabel.fields.emailAddressValidationMessage
    } else {
      errors['emailAddress'] = ''
    }

    if (!values.comments) {
      errors['comments'] = formLabel.fields.commentsValidationMessage
    } else {
      errors['comments'] = ''
    }

    if (
      values.telephoneNumber.length <= 0 ||
      values.telephoneNumber.match(TELEPHONE_REGEX_PATTERN) === null
    ) {
      errors['telephoneNumber'] =
        formLabel.fields.telephoneNumberValidationMessage
    } else {
      errors['telephoneNumber'] = ''
    }

    return errors
  }

  !formValid && formErrors(formValues)

  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const valueInputPrePopulate = useMemo(() => {
    const inputsPrePoulate = {
      firstNameProps: {},
      lastNameProps: {},
      emailProps: {}
    }

    if (!egcContactUsEnabled) {
      return inputsPrePoulate
    }

    inputsPrePoulate.firstNameProps = removeUndefinedProperties({
      value: formValues?.firstName
    })
    inputsPrePoulate.lastNameProps = removeUndefinedProperties({
      value: formValues?.lastName
    })
    inputsPrePoulate.emailProps = removeUndefinedProperties({
      value: formValues?.emailAddress
    })

    return inputsPrePoulate
  }, [formValues, egcContactUsEnabled])

  return (
    <LoadingOverlay loading={userProfile?.isLoading && egcContactUsEnabled}>
      <FormWrapper
        data-testid={formId}
        id={formId}
        onSubmit={onSubmit}
        aria-label='form'
        ref={formRef}
        tabIndex={-1}
        formState={formState}
      >
        {formSuccess && (
          <InfoPanel type='success'>
            <Translation id={`form.${formId}.success`} />
          </InfoPanel>
        )}
        {!formSuccess && (
          <>
            {egcContactUsEnabled && (
              <Row>
                <Field fullWidth>
                  <Input
                    id={ROLE}
                    name={ROLE}
                    label='Role'
                    required={true}
                    disabled
                    value='employee'
                  />
                </Field>
              </Row>
            )}
            <Row>
              <Field>
                <Input
                  id={FIRST_NAME}
                  name='firstName'
                  label={formLabel.fields.firstNameLabel}
                  error={errors.firstName}
                  onChange={handleChange}
                  required={true}
                  disabled={egcContactUsEnabled && userProfile?.firstName}
                  value={formValues?.firstName}
                  {...valueInputPrePopulate?.firstNameProps}
                />
              </Field>
              <Field>
                <Input
                  id={LAST_NAME}
                  name='lastName'
                  label={formLabel.fields.lastNameLabel}
                  error={errors.lastName}
                  onChange={handleChange}
                  required={true}
                  disabled={egcContactUsEnabled && userProfile?.lastName}
                  value={formValues?.lastName}
                  {...valueInputPrePopulate?.lastNameProps}
                />
              </Field>
            </Row>
            <Row>
              <Field fullWidth>
                <Input
                  id={EMAIL}
                  name='emailAddress'
                  label={formLabel.fields.emailAddressLabel}
                  error={errors.emailAddress}
                  onChange={handleChange}
                  required={true}
                  disabled={egcContactUsEnabled && userProfile?.email}
                  value={formValues?.emailAddress}
                  {...valueInputPrePopulate?.emailProps}
                />
              </Field>
            </Row>
            <Row>
              <Field>
                <Input
                  id={PHONE}
                  name='telephoneNumber'
                  label={formLabel.fields.telephoneNumberLabel}
                  error={errors.telephoneNumber}
                  onChange={handleChange}
                  required={true}
                />
              </Field>
            </Row>
            {!egcContactUsEnabled && (
              <Row>
                <Field>
                  <Dropdown
                    id={HELP}
                    name='help'
                    label={formLabel.fields.emailCategoryLabel}
                    options={formLabel.fields.emailCategoryOptionLabel
                      .split(',')
                      .map((feedbackTypeOption, index) => {
                        return {
                          label: feedbackTypeOption,
                          id: `feedbackTypeOption-${index}`,
                          value: `${index}`
                        }
                      })}
                    onChange={handleChange}
                    required={true}
                  />
                </Field>
              </Row>
            )}
            <Row>
              <Field fullWidth>
                <TextArea
                  id={COMMENTS}
                  name='comments'
                  label={formLabel.fields.commentsLabel}
                  helpText={formLabel.fields.commentsSubLabel}
                  error={errors.comments}
                  onChange={handleChange}
                  required={true}
                />
              </Field>
            </Row>
            <Row>
              <Field>
                <Input
                  id={WHICH_SHOP}
                  name='which-shop'
                  label={formLabel.fields.shopInfoLabel}
                  onChange={handleChange}
                />
              </Field>
            </Row>
            <div>
              <input id='pretspam' name='pretspam' type='hidden' />
            </div>
            {isBagZeroValue && (
              <ZeroBagTermsAndConditions>
                <Translation id='checkout.page.payment.terms' />
              </ZeroBagTermsAndConditions>
            )}
            <Row>
              <Button type='submit' disabled={formSubmitting}>
                {formLabel.fields.submitButtonLabel}
              </Button>
            </Row>
          </>
        )}
      </FormWrapper>
    </LoadingOverlay>
  )
}

CmsForm.propTypes = {
  formId: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    'email-address': formValueProps,
    'first-name': formValueProps,
    'last-name': formValueProps,
    'telephone-number': formValueProps
  }),
  isBagZeroValue: PropTypes.bool
}

CmsForm.defaultProps = {
  initialState: {},
  isBagZeroValue: false
}

export default CmsForm
