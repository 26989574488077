import PropTypes from 'prop-types'
import DynamicCallToAction from './dynamic'
import CallToAction from './static'

export const Action = ({ contentType, ...props }) => {
  return contentType === 'dynamicCallToAction' ? (
    <DynamicCallToAction {...props} />
  ) : (
    <CallToAction {...props} />
  )
}

Action.propTypes = {
  contentType: PropTypes.string
}
