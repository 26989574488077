import { Button } from '@pretamanger/component-library'
import { callToActionProps } from '@proptypes'
import LocaleLink from '../locale-link'
import { getColour, getIcon, getLink } from './utils'
import { getClickEvent } from '#src/common/lib/events/publishers/analytics/fire-events/click-events/utils/get-click-event'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

const CallToAction = ({
  slug,
  refType,
  styleType,
  buttonLabel,
  buttonAriaLabel,
  buttonIcon,
  buttonIconColour,
  target,
  href,
  marketingId,
  eventOnClick,
  gaClass,
  gaDesc
}) => {
  const dataMarketingId = marketingId
    ? { 'data-marketing-id': marketingId }
    : {}

  const clickEvent = getClickEvent(eventOnClick)
  const router = useRouter()
  if (
    (buttonIcon == 'appstore' || buttonIcon == 'googleplay') &&
    router.locale === 'fr-FR'
  ) {
    buttonIcon += router.locale.split('-')[1]
  }

  const icon = useMemo(() => {
    if (buttonIcon === 'chevron') {
      return undefined
    }
    const colour = getColour(buttonIconColour)
    const Icon = getIcon(buttonIcon)
    return <Icon colour={colour} />
  }, [buttonIcon, buttonIconColour])

  const shadowNoneButton =
    buttonIcon === 'appstore' || buttonIcon === 'googleplay'
      ? { boxShadow: 'none' }
      : null

  if (slug) {
    const link = getLink({ slug, contentType: refType })
    return (
      <LocaleLink
        href={link.path}
        as={link.url}
        className={`${gaClass}`}
        data-element-desc={gaDesc}
      >
        <Button
          styleType={styleType}
          icon={icon}
          aria-label={buttonAriaLabel}
          onClick={clickEvent}
          {...dataMarketingId}
          className={gaClass}
          data-element-desc={gaDesc}
        >
          {buttonLabel || ''}
        </Button>
      </LocaleLink>
    )
  } else {
    return (
      <Button
        target={target}
        styleType={styleType}
        icon={icon}
        aria-label={buttonAriaLabel}
        href={href}
        onClick={clickEvent}
        style={shadowNoneButton}
        className={gaClass}
        data-element-desc={gaDesc}
        {...dataMarketingId}
      >
        {buttonLabel || ''}
      </Button>
    )
  }
}

CallToAction.propTypes = callToActionProps

export default CallToAction
