import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Heading } from '@pretamanger/component-library'

const DEFAULT_BACKGROUND_COLOUR = 'bg-grey-100'

const COLOURS = {
  grey: tw`${DEFAULT_BACKGROUND_COLOUR}`,
  white: tw`bg-white`
}

const getColour = colour => COLOURS[colour] || DEFAULT_BACKGROUND_COLOUR

export const FullBleed = styled.div`
  ${tw`full-bleed`}
  ${props => getColour(props.backgroundColour)}
`

export const HeadingComponent = styled(Heading)`
  ${tw`px-6`}
`
