import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Wrapper = styled.div`
  a {
    ${tw`
      inline-block
      no-underline text-white rounded px-6 py-2 whitespace-nowrap
      bg-pretRed-700 hover:bg-pretRed-800 focus:bg-pretRed-800`}
  }
`
