import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Heading, LoadingIndicator } from '@pretamanger/component-library'
import { image as imageType } from '@proptypes'
import Translation from '../../translation'
import List from '../../../../products/components/list'
import { Container } from './styles'
import { useAppSelector } from '../../../../state/redux-hooks'
import { accountSelector } from '../../../../state/account-state-slice'

const ProductList = ({ getListProducts, placeholder, products, title }) => {
  const { locale } = useRouter()
  const [mappedProducts, setMappedProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const { loaded } = useAppSelector(accountSelector)

  useEffect(() => {
    if (loaded && !mappedProducts.length) {
      setLoading(true)
      getListProducts(products, locale).then(setMappedProducts)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  return (
    <Container>
      {title && <Heading level='h3'>{title}</Heading>}
      <LoadingIndicator on={loading}>
        <LoadingIndicator.On>
          <span>
            <Translation id='productList.loading' />
          </span>
        </LoadingIndicator.On>
      </LoadingIndicator>
      {!loading && mappedProducts.length > 0 && (
        <List products={mappedProducts} placeholder={placeholder} />
      )}
    </Container>
  )
}

ProductList.propTypes = {
  getListProducts: PropTypes.func.isRequired,
  placeholder: imageType.isRequired,
  products: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string
}

export default ProductList
