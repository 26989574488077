import PropTypes from 'prop-types'
import { CategoryCard } from '@pretamanger/component-library'
import { callToAction, image } from '@proptypes'
import { Action } from '../../call-to-action'
import {
  ActionWrapper,
  ActionContainer,
  FeatureComponent,
  ContentWrapper,
  HeadingComponent
} from './styles'
import { ContentfulImage } from '#src/common/components/contentful-image'
import LocaleLink from '#src/common/components/locale-link'
import { CategoryCardsContainer } from '#src/products/menu-landing-page/styles'
import { mapActionForCategoryCard } from './utils'
import { getImage } from '#src/common/components/image/get-image'

const CtaCollection = ({ actions, headerImage, title }) => {
  const actionsWithoutImage = actions.filter(
    action => !action?.image?.fields?.file?.url
  )

  const renderCategoryCardsContainer =
    actions.length && !actionsWithoutImage.length

  return renderCategoryCardsContainer ? (
    <ContentWrapper>
      {title && <HeadingComponent level='h2'>{title}</HeadingComponent>}
      <CategoryCardsContainer>
        {actions.map(action => {
          const { id, image, link, buttonLabel, slug } =
            mapActionForCategoryCard(action)
          return (
            <CategoryCard
              key={id}
              image={image}
              link={link}
              name={buttonLabel}
              slug={slug}
              variant='large'
              imageComponent={ContentfulImage}
              linkComponent={LocaleLink}
            />
          )
        })}
      </CategoryCardsContainer>
    </ContentWrapper>
  ) : (
    <FeatureComponent
      title={title}
      style='stacked'
      headerImage={
        headerImage && getImage({ img: headerImage, defaultWidth: 182 })
      }
    >
      <ActionContainer>
        {actions.map(action => {
          return (
            <ActionWrapper key={action.id}>
              <Action {...action} />
            </ActionWrapper>
          )
        })}
      </ActionContainer>
    </FeatureComponent>
  )
}

CtaCollection.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(callToAction),
  headerImage: image
}

CtaCollection.defaultProps = {
  headerImage: undefined,
  actions: []
}

export default CtaCollection
