import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Container = styled('section')`
  ${tw`grid gap-8`}
  ${({ columns }) => css`
    @media (min-width: 1024px) {
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));
    }
  `}

  > div {
    ${({ isPlattersLanding }) => isPlattersLanding && tw`grid`}
    p {
      ${({ isPlattersLanding }) => isPlattersLanding && tw`text-[#372F31]`}
    }
  }
`
