import styled from '@emotion/styled'
import tw from 'twin.macro'
import { getScreenSizes } from '@pretamanger/component-library'

const minWidthMedium = `min-width: ${getScreenSizes().md}px`
const minWidthXLarge = `min-width: ${getScreenSizes().xl}px`
const maxWidthLarge = `max-width: ${getScreenSizes().lg}px`

export const GridContainer = styled.div`
  margin: 56px 0 80px;
  @media (${minWidthMedium}) {
    margin: 76px 0 92px;
  }
`

export const GridCards = styled.div`
  ${tw`relative overflow-hidden w-full px-8 flex flex-col md:flex-row flex-wrap content-between lg:justify-center`}
`

export const GridCard = styled.div`
  ${tw`w-full md:w-auto flex flex-col md:flex-row px-6 md:px-0 md:mb-16 xl:mb-0`}

  @media (${minWidthMedium}) {
    max-width: 50%;
  }

  @media (${minWidthXLarge}) {
    max-width: 25%;
  }

  &:last-of-type {
    ${tw`border-b-0 md:border-r-0`}
  }

  @media (${minWidthMedium}) and (${maxWidthLarge}) {
    &:nth-of-type(even) {
      div:nth-of-type(2) {
        > div {
          border-right-width: 0;
        }
      }
    }
  }
`

export const GridCardContent = styled.div`
  ${tw`md:px-10 w-64`}
`

export const GridImage = styled.div`
  img {
    ${tw`mx-auto mb-4`}
  }
`

export const GridBorder = styled.div`
  ${tw`my-10 md:my-0 md:py-4`}

  div {
    ${tw`border-b md:border-b-0 md:border-r md:h-full`}
  }
`

export const GridTitle = styled.div`
  ${tw`font-bold text-xl text-center`}
`

export const GridActionContainer = styled('div')`
  ${tw`mt-4`}
`
