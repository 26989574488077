import styled from '@emotion/styled'
import tw from 'twin.macro'
import { getScreenSizes } from '@pretamanger/component-library'

export const Container = styled.div`
  ${tw`flex flex-col gap-[80px] w-[830px] mx-auto`}

  a {
    ${tw`w-auto`}
  }

  @media (max-width: ${getScreenSizes().md}px) {
    ${tw`gap-[64px] w-[830px] w-full mt-[42px]`}

    a {
      ${tw`w-full`}
    }
  }
`
