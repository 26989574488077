/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { MentionMe } from './mention-me'

export const Referee = props => <MentionMe type='refereefind' {...props} />

Referee.propTypes = {
  calltoactionurl: PropTypes.string,
  customer_id: PropTypes.string,
  email: PropTypes.string,
  firstname: PropTypes.string,
  fullname: PropTypes.string,
  implementation: PropTypes.oneOf(['embed', 'form', 'link']),
  phone_number: PropTypes.string,
  segment: PropTypes.string,
  situation: PropTypes.string.isRequired,
  surname: PropTypes.string
}

Referee.defaultProps = {
  id: 'mmWrapper',
  implementation: 'link'
}
