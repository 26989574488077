import React from 'react'
import { Carousel, CarouselCard, Heading } from '@pretamanger/component-library'
import CustomNextImage from '../../custom-image-component'
import { Action } from '../../call-to-action'
import { carouselSlideProps } from '@proptypes'
import PropTypes from 'prop-types'
import {
  GridContainer,
  GridCards,
  GridCard,
  GridCardContent,
  GridBorder,
  GridImage,
  GridTitle,
  GridActionContainer
} from './styles'

const getImage = (img, imageWidths, defaultAlt) => {
  if (!img) {
    return undefined
  }
  const imageSources = Object.entries(imageWidths).reduce(
    (sources, [size, width]) => ({
      ...sources,
      [size]: `${img.src}?fm=jpg&fl=progressive&w=${width}`
    }),
    {}
  )
  return (
    <CustomNextImage
      sources={imageSources}
      img={{ src: imageSources.xl }}
      alt={img.alt || defaultAlt}
      objectFit='contain'
    />
  )
}
const imageWidths = { xs: 256, sm: 256, md: 256, lg: 256, xl: 256 }
const CarouselContainer = ({
  slides,
  title,
  headingLevel,
  isGrid,
  isDisabled
}) => {
  if (isDisabled && !isGrid) {
    return null
  }
  return !isGrid ? (
    <Carousel title={title} headingLevel={headingLevel}>
      {slides.map(slide => {
        return (
          <CarouselCard
            key={slide.id}
            title={slide.title}
            image={getImage(slide.slideImage, imageWidths, slide.title)}
            action={<Action {...slide.slideAction} />}
          />
        )
      })}
    </Carousel>
  ) : (
    <GridContainer data-testid='grid-container'>
      {title?.length > 1 && (
        <Heading
          level={headingLevel || 'h5'}
          className='text-center pb-8'
          styleOverride='headingMd'
        >
          {title}
        </Heading>
      )}
      <GridCards>
        {slides.map((slide, i) => {
          return (
            <GridCard key={slide.id}>
              <GridCardContent>
                <GridImage>
                  {getImage(slide.slideImage, imageWidths, slide.title)}
                </GridImage>
                <GridTitle>{slide.title}</GridTitle>
                <GridActionContainer>
                  <Action {...slide.slideAction} />
                </GridActionContainer>
              </GridCardContent>
              {i < slides.length - 1 && (
                <GridBorder>
                  <div />
                </GridBorder>
              )}
            </GridCard>
          )
        })}
      </GridCards>
    </GridContainer>
  )
}
CarouselContainer.propTypes = {
  title: PropTypes.string.isRequired,
  headingLevel: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(carouselSlideProps),
  isGrid: PropTypes.bool
}

export default CarouselContainer
