import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { getScreenSizes } from '@pretamanger/component-library'
import tw from 'twin.macro'

const FULL_WIDTH_VARIANTS = [
  'stacked',
  'hero',
  'overlay',
  'stackedHero',
  'featureFullWidth'
]
const HEADER_VARIANTS = ['hero', 'overlay', 'stacked', 'stackedHero']
const RIGHT_AND_LEFT_VARIANTS = ['right', 'left']
const TOP_VARIANTS = 'top'

const fullWidth = css`
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  ${tw`relative w-screen`}
`

const header = css`
  :first-of-type {
    ${tw`-mt-0`}
  }
`

const rightAndLeft = css`
  :first-of-type {
    ${tw`-mt-8`}
  }

  > div {
    ${tw`py-0`}

    @media (min-width: ${getScreenSizes().lg}px) {
      ${tw`py-4`}
    }
  }
`

const topVariant = css`
  > div {
    div:last-child {
      ${tw`grid grid-rows-[minmax(0, auto)] mx-0 gap-4`}
    }
  }
`

export const FeatureSlot = styled('div')`
  ${tw`mt-12`}

  p {
    ${tw`text-[#372F31]`}
  }

  ${({ variant }) => (HEADER_VARIANTS.includes(variant) ? header : '')}
  ${({ variant }) => (FULL_WIDTH_VARIANTS.includes(variant) ? fullWidth : '')}
  ${({ variant }) =>
    RIGHT_AND_LEFT_VARIANTS.includes(variant) ? rightAndLeft : ''}
  ${({ variant }) => (variant === TOP_VARIANTS ? topVariant : '')}
`
export const FeatureHeroSlot = styled.div`
  ${header}
`

export const CampaignBannerSlot = styled('div')`
  ${tw`mt-20`} :first-of-type {
    ${tw`mt-0`}
  }
`

export const MentionMeBanner = styled('div')`
  ${tw`full-bleed lg:w-auto lg:mx-auto lg:mt-12 lg:mb-20`}
`
