import PropTypes from 'prop-types'
import { Signature as SignatureComponent } from '@pretamanger/component-library'
import { Container, ImageContainer } from './styles'
import { getImage } from '#src/common/components/image/get-image'

const Signature = ({ signature, name, icon }) => {
  const imageUrl = signature?.fields?.file?.url
  const imageWidth = signature?.fields?.file?.details?.image?.width
  const imageHeight = signature?.fields?.file?.details?.image?.height

  return (
    <Container>
      <SignatureComponent
        signatureImage={
          <ImageContainer>
            {getImage({
              img: {
                src: `https://${imageUrl}`,
                alt: `${name}-signature`,
                width: imageWidth,
                height: imageHeight
              }
            })}
          </ImageContainer>
        }
        title={name}
        icon={icon}
      />
    </Container>
  )
}

Signature.propTypes = {
  signature: PropTypes.any,
  name: PropTypes.string,
  icon: PropTypes.string
}

export default Signature
