import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, Heading } from '@pretamanger/component-library'

export const Container = styled('div')`
  ${tw`mt-16`}
`

export const AccordionItemTitle = styled(Heading)`
  ${tw`text-2xl! text-pretRed-700`}
`

export const ShowMoreButton = styled(Button)`
  ${tw`mt-8`}
`

export const AccordionItemBody = styled('div')`
  max-width: 540px;
`
