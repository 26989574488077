import base64 from 'base-64'
import { isRoleHasAccess, USER_ROLES } from '#src/loyalty/util'
import { UserRoles } from '#src/state/profile-state-slice'

export const getRole = (
  profileUserRole: Array<UserRoles>,
  queryParamUserRole: string | undefined
) => {
  const isUserHasAccessAsEmployee = isRoleHasAccess(
    profileUserRole,
    USER_ROLES.EMPLOYEE
  )
  const decodeQueryParam =
    queryParamUserRole && base64?.decode(queryParamUserRole)
  const isQueryUserRoleIsEmployee =
    decodeQueryParam && decodeQueryParam?.toUpperCase() === USER_ROLES.EMPLOYEE
  const isUserRoleIsEmployee =
    isUserHasAccessAsEmployee || isQueryUserRoleIsEmployee

  return isUserRoleIsEmployee ? USER_ROLES.EMPLOYEE : USER_ROLES.CUSTOMER
}
