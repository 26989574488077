import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { stringify } from 'querystring'
import useScript from '#src/common/hooks/useScript'
import { isNilOrEmpty } from '#src/common/lib/is-nil-or-empty'
import { Wrapper } from './styles'

const MENTION_ME_API_VERSION = 'v2'
const MENTION_ME_CUSTOMER_ID = 'mm0d13b26b'

const toMentionMeLocaleFormat = locale => locale.replace('-', '_')

const removeExistingKey = key => {
  const firedTags = window.MentionMeFiredTags
  if (!isNilOrEmpty(firedTags) && firedTags[key] === true) {
    delete firedTags[key]
  }
}

export const MentionMe = ({
  apiVersion,
  customerId,
  host,
  id,
  locale,
  type,
  ...params
}) => {
  const [ready, setReady] = useState(true)

  useEffect(() => {
    removeExistingKey(`${params.implementation}${params.situation}`)
  }, [params.implementation, params.situation])

  const callback = () => setReady(false)

  const scriptProps = {
    async: true,
    crossorigin: true,
    src: `${host}/api/${apiVersion}/${type}/${customerId}?${stringify({
      ...params,
      locale: toMentionMeLocaleFormat(locale)
    })}`
  }

  useScript(scriptProps, callback, !ready)

  return <Wrapper id={id} data-testid={`refer-friend-${type}`} />
}

MentionMe.propTypes = {
  apiVersion: PropTypes.string,
  customerId: PropTypes.string,
  id: PropTypes.string,
  host: PropTypes.string,
  locale: PropTypes.string,
  type: PropTypes.oneOf(['refereefind', 'referreroffer', 'dashboard'])
}

MentionMe.defaultProps = {
  apiVersion: MENTION_ME_API_VERSION,
  customerId: MENTION_ME_CUSTOMER_ID,
  id: 'mmWrapper',
  locale: 'en-GB',
  type: 'referreroffer'
}

export default MentionMe
