import styled from '@emotion/styled'
import tw from 'twin.macro'

export const SOCIAL_ICONS_ALIGNMENTS = {
  right: tw`justify-start`,
  left: tw`justify-start`,
  stacked: tw`justify-center`,
  overlay: tw`justify-center`,
  hero: tw`justify-start`,
  boxed: tw`justify-start`,
  stackedHero: tw`justify-start`
} as const

export const SocialNetworksContainer = styled('div')<{
  featureStyle: keyof typeof SOCIAL_ICONS_ALIGNMENTS
}>`
  ${tw`flex mt-8 justify-center`}
  ${({ featureStyle }) => SOCIAL_ICONS_ALIGNMENTS[featureStyle] || ''}
`

export const StrapLineContainer = styled.div`
  ${tw`mt-6 mb-4 text-2xl text-teal`}
  p {
    ${tw`text-2xl leading-snug`}
  }
  del {
    ${tw`text-grey-700`}
  }
`
