import PropTypes from 'prop-types'
import { CardContainer, Heading } from '@pretamanger/component-library'
import CustomNextImage from '#src/common/components/custom-image-component'
import {
  coffeeWizardDefaults,
  coffeeWizardProps,
  image as imageType,
  product
} from '@proptypes'
import LocaleLink from '#src/common/components/locale-link'
import { DietaryBadge, NewBadge } from '#src/common/components/badge'
import { isNew, strictSlugify } from '#src/products/util'
import TransactionalCardContainer from './card-container'
import TransactionalProductCard from './product-card'
import { CardLink, ProductCard } from './styles'

const getImage = (product, placeholder) => {
  const _getImage = height =>
    `${
      (product.image && product.image.src) || placeholder.src
    }?fm=jpg&fl=progressive&h=${height}`

  const imageSources = {
    xs: _getImage(282),
    sm: _getImage(248),
    md: _getImage(304),
    lg: _getImage(266),
    xl: _getImage(248)
  }

  return (
    <CustomNextImage
      sources={imageSources}
      img={{ src: _getImage(248) }}
      alt=''
      useProductWrapper
    />
  )
}

const List = ({
  name,
  products,
  placeholder,
  coffeeWizard,
  isTransactional,
  unavailableForOrderAhead
}) => {
  const Container = isTransactional ? TransactionalCardContainer : CardContainer
  return (
    <Container
      data-testid='product-list'
      title={
        !!name && (
          <Heading level='h2' styleOverride='headingSm'>
            {name}
          </Heading>
        )
      }
    >
      {products.map(product => {
        if (isTransactional) {
          return (
            <TransactionalProductCard
              key={product.id}
              unavailableForOrderAhead={unavailableForOrderAhead}
              coffeeWizard={coffeeWizard}
              {...product}
            />
          )
        }

        const imageBadges = [
          isNew(product.newUntil) && (
            <NewBadge key={`${product.id}-badge-new`} />
          )
        ].filter(badge => badge)

        const dietaryBadge = [
          (product.suitableForVegans && (
            <DietaryBadge key={`${product.id}-badge-vg`} diet='vg' />
          )) ||
            (product.suitableForVegetarians && (
              <DietaryBadge key={`${product.id}-badge-v`} diet='v' />
            ))
        ].filter(badge => badge)

        const wrappingLink = `productLink-${product.id}`

        return (
          <ProductCard
            key={product.id}
            cardBadges={dietaryBadge}
            image={getImage(product, placeholder)}
            imageBadges={imageBadges}
            wrappingLink={wrappingLink}
            data-testid='product-card'
          >
            <LocaleLink
              href='/products/[sku]/[name]'
              as={`/products/${product.sku}/${encodeURIComponent(
                strictSlugify(product.name)
              )}`}
            >
              <CardLink id={wrappingLink}>
                <Heading level='h3' styleOverride='heading2Xs'>
                  {product.name}
                </Heading>
              </CardLink>
            </LocaleLink>
          </ProductCard>
        )
      })}
    </Container>
  )
}

List.propTypes = {
  coffeeWizard: coffeeWizardProps,
  isTransactional: PropTypes.bool,
  name: PropTypes.string,
  placeholder: imageType.isRequired,
  products: PropTypes.arrayOf(product),
  unavailableForOrderAhead: PropTypes.bool
}

List.defaultProps = {
  coffeeWizard: coffeeWizardDefaults,
  isTransactional: false,
  products: [],
  unavailableForOrderAhead: false
}

export default List
