import { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { formValueProps } from '@proptypes'
import { Button, ErrorWarning } from '@pretamanger/component-library'
import { validateForm } from '../../lib/api/form'
import { mapLocale } from '../../lib/locale'
import Translation, { translate } from '../translation'
import InfoPanel from '../info-panel'
import forms from './forms'
import {
  FormWrapper,
  Row,
  WarningWrapper,
  ZeroBagTermsAndConditions
} from './styles'

const getTranslatedFieldName = (ref, locale) => {
  return translate(`form.${ref}.name`, locale)
    ? translate(`form.${ref}.name`, locale)?.value
    : translate(`form.${ref}.label`, locale)?.value
}

const getPropTranslation = (ref, locale, isRichText) => {
  return isRichText ? (
    <Translation id={`form.${ref}`} />
  ) : (
    translate(`form.${ref}`, locale)?.value
  )
}

export const getFieldProps = (
  formId,
  locale,
  id,
  dirty,
  valid,
  values,
  options = {}
) => ({
  id,
  helpText: getPropTranslation(`${formId}.${id}.help`, locale),
  label: getPropTranslation(
    `${formId}.${id}.label`,
    locale,
    options.isRichTextLabel
  ),
  defaultValue: values[id] && values[id].value,
  error:
    dirty && !valid
      ? getPropTranslation(`${formId}.${id}.error`, locale)
      : undefined
})

export const getFieldOptions = (formId, locale, id) => {
  const options = translate(`form.${formId}.${id}.options`, locale) || {}
  return Object.keys(options)
    .filter(key => options[key].value !== '')
    .map(key => ({ id: key, value: key, label: options[key].value }))
}

const Form = ({ formId, submitHandler, initialState, isBagZeroValue }) => {
  const formRef = useRef(null)
  const { locale } = useRouter()
  const mappedLocale = mapLocale(locale, true)

  const [formState, setFormState] = useState(initialState)
  const [formValid, setFormValid] = useState(true)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)

  const Form = forms[formId]

  const getProps = (id, dirty, valid, options) =>
    getFieldProps(formId, mappedLocale, id, dirty, valid, initialState, options)
  const getOptions = id => getFieldOptions(formId, mappedLocale, id)

  const getFormError = () => {
    const errorList = []
    for (const field in formState) {
      if (!formState[field].isValid) {
        errorList.push(formState[field].id)
      }
    }
    const formErrorTitle = translate(
      `form.${formId}.error.title`,
      locale
    )?.value
    const formErrorMessage = translate(
      `form.${formId}.invalid-fields`,
      locale,
      { count: errorList.length }
    )?.value
    if (formErrorTitle && formErrorMessage) {
      const translatedErrors = errorList.map(field =>
        getTranslatedFieldName(`${formId}.${field}`, locale)
      )
      return {
        title: formErrorTitle,
        message: `${formErrorMessage} ${translatedErrors.join(', ')}`
      }
    }
  }

  const onSubmit = async e => {
    e.preventDefault()

    const fieldArray = []
    const rows = Array.from(e.target.children)
    rows.forEach(row => {
      fieldArray.push(
        ...(row.querySelectorAll('input, textarea, select') || [])
      )
    })

    const formValues = fieldArray.map(f => {
      const { id, value, options, checked, selectedIndex } = f
      let item
      if (id) {
        item = { id, value }
      }
      if (options) {
        item.description = options[selectedIndex]?.text
      }
      if (checked) {
        item.value = true
      }
      return item
    })

    const { formState, formValid } = validateForm(
      Form.formDefinition,
      formValues
    )
    setFormState(formState)
    setFormValid(formValid)

    if (formValid) {
      setFormSubmitting(true)
      const result = await submitHandler(
        Form.formDefinition,
        formValues,
        mappedLocale
      )
      if (result) {
        setFormSubmitting(false)
        if (result.errors) {
          setFormState(result.errors)
          setFormValid(false)
        } else {
          setFormSuccess(true)
        }
      }
    }
    formRef.current.focus()
  }

  const formError = !formValid && getFormError()

  const submitButtonFormId = isBagZeroValue ? 'order-details-zero-bag' : formId

  return (
    <FormWrapper
      data-testid={formId}
      id={formId}
      onSubmit={onSubmit}
      aria-label='form'
      ref={formRef}
      tabIndex={-1}
    >
      {formError && (
        <WarningWrapper data-formid={formId}>
          <ErrorWarning title={formError?.title} error={formError?.message} />
        </WarningWrapper>
      )}
      {formSuccess && (
        <InfoPanel type='success'>
          <Translation id={`form.${formId}.success`} />
        </InfoPanel>
      )}
      {!formSuccess && (
        <>
          <Form
            formState={formState}
            getOptions={getOptions}
            getProps={getProps}
          />
          <div>
            <input id='pretspam' name='pretspam' type='hidden' />
          </div>
          {isBagZeroValue && (
            <ZeroBagTermsAndConditions>
              <Translation id='checkout.page.payment.terms' />
            </ZeroBagTermsAndConditions>
          )}
          <Row>
            <Button type='submit' disabled={formSubmitting}>
              {
                translate(
                  `form.${submitButtonFormId}.submit.label`,
                  mappedLocale
                )?.value
              }
            </Button>
          </Row>
        </>
      )}
    </FormWrapper>
  )
}

Form.propTypes = {
  formId: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    'email-address': formValueProps,
    'first-name': formValueProps,
    'last-name': formValueProps,
    'telephone-number': formValueProps
  }),
  isBagZeroValue: PropTypes.bool
}

Form.defaultProps = {
  initialState: {},
  isBagZeroValue: false
}

export default Form
