import React, { ReactNode } from 'react'
import { FeatureFullWidth as FFW, Video } from '@pretamanger/component-library'
import { RichText } from '#src/common/components/content'
import { Action } from '#src/common/components/call-to-action'
import { ImageType, MAIN_IMAGE_SOURCES } from '../feature'
import { getImage } from '#src/common/components/image/get-image'
import {
  AssetShape,
  CallToActionProps
} from '#src/common/components/page-slots/types'

interface FeatureFullWidthProps {
  actions: CallToActionProps[]
  text: ReactNode
  title: string
  video: {
    fields: {
      asset: AssetShape
      poster: AssetShape
      captions: AssetShape
    }
  }
  image: ImageType
  imagePosition: boolean
  greyBackground: boolean
}

const FeatureFullWidth: React.FunctionComponent<FeatureFullWidthProps> = ({
  actions,
  text,
  title,
  video,
  image,
  imagePosition,
  greyBackground
}) => {
  const RT = RichText as (props: { text: any }) => JSX.Element
  return (
    <FFW
      actions={
        actions?.length &&
        actions.map(action => <Action key={action.id} {...action} />)
      }
      title={title}
      video={
        video && (
          <Video
            captions={video.fields?.captions?.fields?.file?.url}
            width='100%'
            height='100%'
            source={video.fields?.asset?.fields?.file?.url}
            poster={video.fields?.poster?.fields?.file?.url}
          />
        )
      }
      image={
        image &&
        getImage({
          img: {
            ...image,
            alt: title
          },
          imageWidths: MAIN_IMAGE_SOURCES,
          defaultWidth: 2000,
          priority: true,
          customWrapperStyle: { zIndex: 1 }
        })
      }
      imagePosition={imagePosition}
      greyBackground={greyBackground}
    >
      <RT text={text} />
    </FFW>
  )
}

export default FeatureFullWidth
