import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CardContainerWrapper = styled('div')`
  ${tw`w-full`}
`

export const ContainerTitle = styled('div')`
  ${tw`mb-3 pr-2`}
`

export const Cards = styled('div')`
  ${tw`grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 sm:gap-4 sm:pb-8`}
  ${({ compact }) => compact && tw`lg:grid-cols-1 xl:grid-cols-2`}
`
