import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  AccordionContainer,
  AccordionItem,
  Heading
} from '@pretamanger/component-library'
import { RichText } from '../../content'
import {
  AccordionItemTitle,
  Container,
  ShowMoreButton,
  AccordionItemBody
} from './styles'
import { richText } from '@proptypes'
import FeatureContainer from '../feature-container'

const Accordion = ({
  entries,
  entriesToDisplay,
  expandedEntry,
  showLessText,
  showMoreText,
  title,
  identifier
}) => {
  const [showAll, setShowAll] = useState(false)
  const toggleShowAll = () => setShowAll(!showAll)
  const ids = entries.map(({ title }) =>
    title.replaceAll(' ', '-').toLowerCase()
  )
  return (
    <Container role='list' id={identifier}>
      <AccordionContainer title={<Heading level='h3'>{title}</Heading>}>
        {entries
          .slice(0, showAll ? entries.length : entriesToDisplay)
          .map(({ id, title, body, additionalContent }, i) => (
            <AccordionItem
              id={ids[i]}
              key={id}
              title={
                <AccordionItemTitle level='h4' styleOverride='headingBodyLg'>
                  {title}
                </AccordionItemTitle>
              }
              expanded={expandedEntry === i}
              role='listitem'
            >
              <AccordionItemBody>
                <RichText text={body} />
              </AccordionItemBody>
              {additionalContent &&
                additionalContent.contentType === 'featureContainer' && (
                  <FeatureContainer
                    id={additionalContent.id}
                    columns={additionalContent.columns}
                    features={additionalContent.features}
                  />
                )}
            </AccordionItem>
          ))}
      </AccordionContainer>
      {entries.length > entriesToDisplay && (
        <ShowMoreButton styleType='secondary' onClick={toggleShowAll}>
          {showAll ? showLessText : showMoreText}
        </ShowMoreButton>
      )}
    </Container>
  )
}

Accordion.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: richText.isRequired
    })
  ).isRequired,
  showLessText: PropTypes.string.isRequired,
  showMoreText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  expandedEntry: PropTypes.number,
  entriesToDisplay: PropTypes.number,
  identifier: PropTypes.string
}

Accordion.defaultProps = {
  expandedEntry: undefined,
  entriesToDisplay: 6
}

export default Accordion
