import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Heading, getScreenSizes } from '@pretamanger/component-library'

const bannerHeightXs = 600
const bannerHeightMd = 568

export const CampaignBannerWrapper = styled('div')`
  ${tw`relative flex justify-end overflow-hidden px-2 py-4 full-bleed`}
  min-height: ${bannerHeightXs}px;

  @media (min-width: ${getScreenSizes().sm}px) {
    ${tw`px-5 py-8`}
    min-height: ${bannerHeightMd}px;
  }
`

export const CampaignImageContainer = styled('div')`
  ${tw`absolute z-10 min-w-full`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    ${tw`min-w-full max-w-none`}
    min-height: ${bannerHeightXs}px;

    @media (min-width: ${getScreenSizes().sm}px) {
      ${tw`w-auto`}
      min-height: ${bannerHeightMd}px;
    }
  }
`

export const CampaignBannerContent = styled('div')`
  ${tw`relative w-full flex flex-col items-start justify-start z-20 py-2 px-4`}

  @media (min-width: ${getScreenSizes().sm}px) {
    ${tw`w-1/2 p-0 justify-center`}
  }
`

export const CampaignBannerHeading = styled(Heading)`
  &&& {
    ${tw`block text-white`}
  }
`

export const CampaignBannerSubHeading = styled(Heading)`
  &&& {
    ${tw`block text-white mt-3 text-lg font-medium max-w-xl`}

    @media (min-width: ${getScreenSizes().sm}px) {
      ${tw`text-xl leading-xl mt-2`}
    }
  }
`

export const ActionContainer = styled('div')`
  ${tw`mt-4`}
  a, button {
    ${tw`bg-white border-white  text-grey-700 hover:text-grey-700 hover:bg-grey-50 focus:text-grey-700 focus:bg-grey-50 `}

    &:hover,
     &:focus {
      span {
        ${tw`text-grey-700 hover:text-grey-700 `}
      }
    }

    span {
      ${tw`text-grey-700`}
    }
  }
`
