import PropTypes from 'prop-types'
import Feature from '../feature'
import { Container } from './styles'

const FeatureContainer = ({ id, columns, features, isPlattersLanding }) => {
  /* eslint-disable no-unused-vars  */
  return (
    <Container
      id={id}
      columns={columns}
      data-testid='feature-container'
      isPlattersLanding={isPlattersLanding}
    >
      {features.map(({ contentType, id: featureId, ...props }) => (
        <Feature key={featureId} id={featureId} {...props} />
      ))}
    </Container>
  )
  /* eslint-enable no-unused-vars  */
}

FeatureContainer.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.shape(Feature.propTypes)),
  isPlattersLanding: PropTypes.bool
}

FeatureContainer.defaultProps = {
  columns: 1,
  features: [],
  isPlattersLanding: false
}

export default FeatureContainer
