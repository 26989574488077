import PropTypes from 'prop-types'
import {
  FeatureGrid as Grid,
  featureTemplates
} from '@pretamanger/component-library'
import { richText } from '@proptypes'
import { RichText } from '#src/common/components/content'
import { FullBleed, HeadingComponent } from './styles'
import { createCallToAction } from '../feature-item/create-call-to-action'

const Empty = () => null

const parseTextField = field =>
  typeof field === 'string' ? field : <RichText text={field} withoutWrapper />

export const FeatureGrid = ({
  backgroundColour,
  featureItems,
  featureItemTemplate,
  fullBleed,
  title,
  width
}) => {
  const Template = featureTemplates.featureItem[featureItemTemplate] || Empty

  const generateIconDimension = (dimension, key) => {
    if (!dimension) {
      return {}
    }
    return { [key]: dimension }
  }

  const content = (
    <>
      {title && (
        <HeadingComponent level='h2' className='md:text-center pt-6'>
          {title}
        </HeadingComponent>
      )}
      <Grid width={width} count={featureItems.length}>
        {featureItems.map(({ fields }, index) => {
          const { body, template, tooltipText, ...otherFields } = fields
          let callToAction
          if (['feature-item-promotion'].includes(template)) {
            callToAction = createCallToAction(otherFields?.actions?.[0])
          }
          const props = {
            ...otherFields,
            ...(body && { body: parseTextField(body) }),
            ...(tooltipText && { tooltipText: parseTextField(tooltipText) }),
            ...generateIconDimension(
              fields.iconDimension && fields.iconDimension[0],
              'iconWidth'
            ),
            ...generateIconDimension(
              fields.iconDimension &&
                fields.iconDimension.length > 1 &&
                fields.iconDimension[1],
              'iconHeight'
            )
          }
          return (
            <Template
              key={`${fields.identifier}-${index}`}
              headingSize='heading2Xs'
              callToAction={callToAction}
              {...props}
            />
          )
        })}
      </Grid>
    </>
  )

  if (fullBleed) {
    return (
      <FullBleed backgroundColour={backgroundColour}>
        <div className='container mx-auto'>{content}</div>
      </FullBleed>
    )
  }

  return content
}

FeatureGrid.propTypes = {
  backgroundColour: PropTypes.string.isRequired,
  featureItems: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        body: PropTypes.oneOfType([richText, PropTypes.string]),
        icon: PropTypes.string,
        title: PropTypes.string.isRequired
      })
    })
  ).isRequired,
  featureItemTemplate: PropTypes.string.isRequired,
  fullBleed: PropTypes.bool.isRequired,
  title: PropTypes.string
}
