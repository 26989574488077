import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAppSelector, useAppDispatch } from '#src/state/redux-hooks'
import { profileSelector, fetchProfile } from '#src/state/profile-state-slice'
import { envSelector } from '#src/state/env-state-slice'
import { accountSelector } from '#src/state/account-state-slice'

export const useProfile = () => {
  const [token, setToken] = useState('')
  const envState = useAppSelector(envSelector)
  const { query } = useRouter()
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(accountSelector)

  useEffect(() => {
    if (query?.token && typeof query?.token == 'string') {
      setToken(query.token)
    }
  }, [query?.token])

  useEffect(() => {
    if (envState?.auth0Audience) {
      if (accountState?.isAuthenticated) {
        dispatch(fetchProfile({ token: '' }))
      } else if (token !== '') {
        dispatch(fetchProfile({ token }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envState?.auth0Audience, token, accountState?.isAuthenticated])
  const profileState = useAppSelector(profileSelector)

  return profileState
}
