import PropTypes from 'prop-types'
import { featureTemplates } from '@pretamanger/component-library'
import { callToAction, image, richText } from '@proptypes'
import { RichText } from '#src/common/components/content'
import { StrapLine } from '#src/common/components/content/markdown/strap-line'
import { Action } from '#src/common/components/call-to-action'
import { ContentfulImage } from '#src/common/components/contentful-image'

export const FeatureHero = ({
  actions,
  body,
  strapline,
  image: { alt, height, src, width },
  template,
  title,
  subText
}) => {
  const Template = featureTemplates.featureHero[template]
  return (
    <Template
      actions={actions.map(action => (
        <Action {...action} key={action.identifier} />
      ))}
      image={
        <ContentfulImage
          alt={alt || ''}
          src={`https://${src}`}
          height={height}
          width={width}
          priority
        />
      }
      subText={
        subText && (
          <RichText
            text={subText}
            openHyperLinksInNewWindow={false}
            withoutWrapper
          />
        )
      }
      text={<RichText text={body} withoutWrapper />}
      strapLine={strapline && <StrapLine content={strapline} />}
      title={title}
    />
  )
}

FeatureHero.propTypes = {
  actions: PropTypes.arrayOf(callToAction),
  body: richText.isRequired,
  subText: richText,
  strapLine: PropTypes.string,
  image,
  template: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

FeatureHero.defaultProps = {
  actions: [],
  subText: null
}
