import PropTypes from 'prop-types'
import { richText } from '@proptypes'
import { featureTemplates } from '@pretamanger/component-library'
import { RichText } from '#src/common/components/content'
import { createCallToAction } from './create-call-to-action'

const Empty = () => null

const parseTextField = field =>
  typeof field === 'string' ? field : <RichText text={field} withoutWrapper />

export const FeatureItem = ({ body, subText, template, ...otherProps }) => {
  const Template = featureTemplates.featureItem[template] || Empty
  let callToAction
  if (otherProps?.actions?.length) {
    callToAction = createCallToAction(otherProps.actions[0])
  }

  return (
    <Template
      body={parseTextField(body)}
      subText={subText && parseTextField(subText)}
      callToAction={callToAction}
      {...otherProps}
    />
  )
}

FeatureItem.propTypes = {
  featureItems: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        body: PropTypes.oneOfType([richText, PropTypes.string]),
        subText: PropTypes.oneOfType([richText, PropTypes.string]),
        icon: PropTypes.string,
        title: PropTypes.string.isRequired
      })
    })
  ),
  template: PropTypes.string.isRequired,
  title: PropTypes.string
}
