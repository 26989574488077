import { Button } from '@pretamanger/component-library'
import { dynamicCallToActionProps } from '@proptypes'
import { getColour, getIcon } from './utils'
import { coffeeSubscriptionPlanSelection } from './actions'
import { coffeeSubsUSGetStartedEvent } from '#src/common/lib/events/publishers/analytics/fire-events/click-events/coffee-subs-us-get-started-event'

export const getOnButtonClick = planSelectionHandler => {
  return (event, identifier) => {
    coffeeSubsUSGetStartedEvent(identifier)
    if (planSelectionHandler) planSelectionHandler(event)
  }
}

export const getPlanSelectionHandler = action => {
  const planSelectionHandlersByAction = {
    'Coffee Subscription Plan Selection': coffeeSubscriptionPlanSelection
  }

  return planSelectionHandlersByAction[action]
}

const DynamicCallToAction = ({
  styleType,
  buttonLabel,
  buttonIcon,
  buttonIconColour,
  action,
  marketingId,
  identifier,
  gaClass,
  gaDesc
}) => {
  const onButtonClick = getOnButtonClick(getPlanSelectionHandler(action))

  const Icon = getIcon(buttonIcon)
  return (
    <Button
      data-action-id={identifier}
      styleType={styleType}
      onClick={event => onButtonClick(event, identifier)}
      icon={<Icon colour={getColour(buttonIconColour)} />}
      className={`${gaClass}`}
      data-element-desc={gaDesc}
      {...(marketingId && { 'data-marketing-id': marketingId })}
    >
      {buttonLabel || ''}
    </Button>
  )
}

DynamicCallToAction.propTypes = dynamicCallToActionProps

export default DynamicCallToAction
