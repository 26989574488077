import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Heading, Button } from '@pretamanger/component-library'

export const DropdownContainer = styled('div')`
  ${tw`w-full flex flex-wrap md:flex-nowrap mb-5 mt-4 justify-between`}
  @media (max-width: 680px) {
    ${tw`flex-col h-28 mb-2 mt-2`}
  }

  > div {
    width: calc(50% - 1rem);
    @media (max-width: 680px) {
      ${tw`w-full`}
    }
  }
`

export const ButtonContainer = styled('div')`
  ${tw`w-4/5 flex flex-wrap md:flex-nowrap mb-6 justify-between`}
  @media (max-width: 680px) {
    ${tw`w-full`}

    > button {
      display: inline-block;
      ${tw`px-4 text-xs`}
    }
  }
`

export const ResultContainer = styled('div')`
  ${tw`w-full block md:flex-nowrap mb-4`}
`

export const TextContainer = styled('div')`
  ${tw`flex mt-3 mb-4 justify-between`}
  margin-right: 13%;
  @media (max-width: 680px) {
    ${tw`flex-col`}
    margin-right: 0;
  }
`

export const Text = styled('span')`
  ${tw`inline mr-3 text-grey-700 text-xl`}
  @media (max-width: 680px) {
    ${tw`self-center`}
  }
`

export const Separator = styled('hr')`
  ${tw`block bg-grey-700 h-px mt-2 mb-2`};
`

export const ComparisonTitle = styled(Heading)`
  ${tw`block font-semibold leading-tight not-italic text-2xl sm:text-3xl items-start mb-6`}
`

export const DisclaimerText = styled('span')`
  ${tw`block text-grey-700 text-xs`}
`

export const BannerText = styled('span')<{
  isPromo?: boolean
  isClubPretFlow?: boolean
}>`
  ${({ isPromo, isClubPretFlow }) =>
    isPromo
      ? tw`text-grey-700`
      : isClubPretFlow
      ? tw`text-white`
      : tw`text-pretRed-800`}
  ${tw`text-2xl text-center align-middle`}
  padding-left: 5%;
  padding-right: 5%;
`

export const BannerTextWrapper = styled('div')<{
  isPromo?: boolean
  withMarginBottom?: boolean
  isClubPretFlow?: boolean
}>`
  ${({ isPromo, isClubPretFlow }) =>
    isPromo
      ? tw`bg-[#CBF174]`
      : isClubPretFlow
      ? tw`bg-[#862633] mt-4`
      : tw`bg-grey-50 mt-4`}
  ${({ withMarginBottom }) => withMarginBottom && tw`mb-8`}
  ${tw`h-20 flex align-middle justify-center items-center self-center`}
  width: calc(100% + 4rem);
  @media (max-width: 680px) {
    width: calc(100% + 3rem);
  }
`

export const FillerWrapper = styled('div')`
  ${tw`w-full flex`}
  height: 4.1rem;
`

export const ButtonWrapper = styled('div')`
  ${tw`flex align-middle justify-center items-center self-center`}
  width: calc(100% + 4rem);
  @media (max-width: 680px) {
    width: calc(100% + 3rem);
  }
`

export const CustomizeButton = styled(Button)`
  ${tw`w-full bg-[#862633] h-12 p-0`}
  span {
    ${tw`font-bold text-center align-middle `}
  }
`

export const CustomizeLink = styled('a')`
  ${tw`w-full p-0 mx-2`}
`
