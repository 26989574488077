import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Grid, GridArea, getScreenSizes } from '@pretamanger/component-library'

export const PageWithSideNavGrid = styled(Grid)`
  grid-template-rows: auto 1fr;
  ${tw`mx-4 md:mx-auto max-w-sm sm:max-w-none`}
`
export const SideNavGridArea = styled(GridArea)`
  @media (max-width: ${getScreenSizes().lg - 1}px) {
    ${props =>
      props.isStickyMobileNav && [
        tw`full-bleed sticky left-0 border-t border-grey-200 z-30`,
        'top: var(--sticky-header-height, 0)'
      ]}
  }
`

export const CookieList = styled('div')`
  padding-top: 1em;
  column-gap: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #575354;
  font-family: 'Sofia Pro', sans-serif;

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
    --tw-text-opacity: 1;
    color: rgba(55, 47, 49, var(--tw-text-opacity)) !important;
    font-size: 18px;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title {
    --tw-text-opacity: 1;
    color: rgba(55, 47, 49, var(--tw-text-opacity));
    font-size: 24px;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy
    .ot-sdk-cookie-policy-group-desc,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    letter-spacing: 0px;
    color: #575354;
    font-family: 'Sofia Pro', sans-serif;
  }

  #onetrust-banner-sdk a,
  #onetrust-pc-sdk a,
  #ot-sdk-cookie-policy a {
    --tw-text-opacity: 1;
    color: rgb(159 27 50 / var(--tw-text-opacity)) !important;
  }
`
