import { Action } from '#src/common/components/call-to-action'
import { Heading } from '@pretamanger/component-library'
import PropTypes from 'prop-types'
import Image from '../../../../common/components/content/markdown/image'
import {
  ContentWrapper,
  OrderConfirmationCustomSlotWrapper,
  OrderConfirmationCustomSlot,
  Subtitle,
  ActionContainer
} from './styles'

export const OrderCustomInformation = props => {
  const images =
    props.images
      ?.filter(img => img.fields?.file.url)
      .map(img => {
        return {
          description: img.fields?.description,
          src: img.fields?.file.url,
          title: img.fields?.title
        }
      }) || []
  const callToAction = props?.callToAction
  return (
    <ContentWrapper>
      <div className='py-6 px-4 py-6 md:p-10'>
        <Heading level='h1' styleOverride='headingXs'>
          {props.title}
        </Heading>
        <Subtitle>{props.subtitle}</Subtitle>
        <OrderConfirmationCustomSlotWrapper>
          {images.map((image, index) => (
            <OrderConfirmationCustomSlot
              key={index}
              isOdd={images.length % 2 !== 0}
            >
              <Image src={image.src} alt={image.title} title={image.title} />
              <p>{image.description}</p>
            </OrderConfirmationCustomSlot>
          ))}
        </OrderConfirmationCustomSlotWrapper>
        {callToAction && (
          <ActionContainer>
            <Action {...callToAction} />
          </ActionContainer>
        )}
      </div>
    </ContentWrapper>
  )
}

const OrderConfirmationSlot = PropTypes.shape({
  id: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  images: PropTypes.array,
  displayOrder: PropTypes.number.isRequired
})

OrderCustomInformation.propTypes = {
  content: OrderConfirmationSlot
}
