import PropTypes from 'prop-types'
import { CardContainerWrapper, Cards, ContainerTitle } from './styles'

const Container = ({ children, title, compact }) => (
  <CardContainerWrapper id='product-list'>
    <ContainerTitle>{title}</ContainerTitle>
    <Cards compact={compact}>{children}</Cards>
  </CardContainerWrapper>
)

Container.defaultProps = {
  compact: false
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  compact: PropTypes.bool,
  title: PropTypes.node.isRequired
}

export default Container
