import { FeatureItemList } from '@pretamanger/component-library'
import { useState } from 'react'
import { Container } from './styled'
import { createCallToAction } from '../feature-item/create-call-to-action'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

interface FeatureItemData {
  identifier: string
  template: string
  icon?: string
  title?: string
  body?: any
  headingLevel?: string
  headingSize?: string
  styleType?: string
  hotPinkTheme?: boolean
  iconPosition?: string
  iconDimension?: number[]
  tooltipText?: any
}

interface FeatureItemListProps {
  title: string
  featureItems: {
    fields: FeatureItemData
  }[]
  callToAction?: object
}

export const ItemList = ({
  title,
  featureItems,
  callToAction
}: FeatureItemListProps) => {
  const [itemsData] = useState(featureItems)
  const [actionObject] = useState(callToAction)

  const setRichText = (data: any) => {
    const newData = data.map(item => {
      const newObj = { ...item.fields }
      newObj.body = documentToReactComponents(item.fields.body)
      if (item.fields.tooltipText) {
        newObj.tooltipText = documentToReactComponents(item.fields.tooltipText)
      }
      newObj.iconWidth =
        item.fields.iconDimension && item.fields.iconDimension[0]
      newObj.iconHeight =
        item.fields.iconDimension &&
        item.fields.iconDimension.length > 1 &&
        item.fields.iconDimension[1]
      return newObj
    })
    return newData
  }

  const actions = data => {
    const newData = data?.map(({ fields }) => {
      const props = { ...fields }
      props.styleType = fields.buttonType
      props.href = fields.internalUrl || fields.externalUrl
      props.id = fields.identifier.replaceAll(' ', '-')
      props.target = fields?.openInANewWindow ? '_blank' : '_self'
      return createCallToAction(props)
    })
    return newData
  }

  return (
    <Container>
      <FeatureItemList
        title={title}
        featureItems={setRichText(itemsData)}
        actions={actions(actionObject)}
      />
    </Container>
  )
}
