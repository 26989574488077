import styled from '@emotion/styled'
import tw from 'twin.macro'
import { getScreenSizes } from '@pretamanger/component-library'

export const ContentWrapper = styled('section')`
  ${tw`border-grey-100 md:border-2 overflow-hidden relative mt-4 `}
  svg {
    transform: scale(0.9);
  }
  @media (min-width: ${getScreenSizes().xs}px) {
    min-height: 15rem;
  }
  @media (min-width: ${getScreenSizes().md}px) {
    svg {
      transform: scale(1.1);
    }
  }
`

export const OrderConfirmationCustomSlot = styled('div')`
  ${tw`text-center w-full md:w-6/12 mt-6`}
  &:last-child {
    ${({ isOdd }) => (isOdd ? tw`w-full` : '')}
  }
  img {
    ${tw`m-auto`}
    max-height: 120px;
  }
`

export const OrderConfirmationCustomSlotWrapper = styled('div')`
  ${tw`flex flex-wrap md:px-32`}
`

export const Subtitle = styled('p')`
  ${tw`mt-2 mb-6`}
`

export const ActionContainer = styled('div')`
  ${tw`flex flex-wrap m-2`}
`
