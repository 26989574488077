import CustomNextImage from '#src/common/components/custom-image-component'

type Img = {
  src: string
  alt?: string
  height?: number
  width?: number
}

type ImageWidths = {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

type ImageSources = {
  xs?: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
}

const IMAGE_WIDTHS = {
  xs: 800,
  sm: 800,
  md: 800,
  lg: 800,
  xl: 800
}

type GetImageArgs = {
  img: Img
  imageWidths?: ImageWidths
  fm?: string
  defaultWidth?: number
  ariaHidden?: boolean
  priority?: boolean
  useProductWrapper?: boolean
  customWrapperStyle?: any
}

export const getImage = ({
  img,
  imageWidths = IMAGE_WIDTHS,
  fm = 'jpg',
  defaultWidth = 800,
  ariaHidden,
  priority = false,
  useProductWrapper = false,
  customWrapperStyle = {}
}: GetImageArgs) => {
  const _getImage = width => {
    let source
    if (typeof img === 'object') {
      source = img.src
    } else {
      source = img
    }
    return `${source}?fm=${fm}${
      fm !== 'webp' ? '&fl=progressive' : ''
    }&w=${width}`
  }

  const imageSources: ImageSources = Object.entries(
    imageWidths
  ).reduce<ImageSources>(
    (sources, [size, width]) => ({
      ...sources,
      [size]: _getImage(width)
    }),
    {}
  )

  return (
    <CustomNextImage
      sources={imageSources}
      imageWidths={imageWidths}
      img={img}
      defaultWidth={defaultWidth}
      alt={img.alt ?? ''}
      priority={priority}
      ariaHidden={ariaHidden}
      customWrapperStyle={customWrapperStyle}
      {...(useProductWrapper && { useProductWrapper })}
    />
  )
}
