const STRIKETHRU_TEMPLATE = /{{(.*?)}}/g

type StrapLineProps = {
  content: string
}

export const StrapLine = ({ content }: StrapLineProps) => {
  const [pre, strikethru, post] = content.split(STRIKETHRU_TEMPLATE)
  return (
    <p data-testid='strapline'>
      {pre && <span>{pre}</span>}
      {strikethru && <del>{strikethru}</del>}
      {post && <span>{post}</span>}
    </p>
  )
}
