/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { MentionMe } from './mention-me'

export const Referrer = props => <MentionMe {...props} />

Referrer.propTypes = {
  address_line1: PropTypes.string,
  address_line2: PropTypes.string,
  address_city: PropTypes.string,
  address_county: PropTypes.string,
  address_postcode: PropTypes.string,
  address_country: PropTypes.string,
  custom_share_field: PropTypes.string,
  coupon_code: PropTypes.string,
  custom_field: PropTypes.string,
  customer_id: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  fullname: PropTypes.string,
  id: PropTypes.string,
  implementation: PropTypes.oneOf(['embed', 'form', 'link']),
  mm_e: PropTypes.string,
  phone_number: PropTypes.string,
  segment: PropTypes.string,
  signup_date: PropTypes.string,
  signup_id: PropTypes.string,
  situation: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  title: PropTypes.string,
  username: PropTypes.string
}

Referrer.defaultProps = {
  id: 'mmWrapper'
}
