import {
  Email,
  Facebook,
  Instagram,
  Phone,
  Twitter,
  AppStore,
  GooglePlay,
  AppStoreFrench,
  GooglePlayFrench
} from '@pretamanger/component-library'

export const getColour = buttonIconColour =>
  buttonIconColour === 'green' ? 'veggiePretGreen' : 'pretRed-700'

export const getIcon = icon => {
  return {
    email: Email,
    phone: Phone,
    twitter: Twitter,
    facebook: Facebook,
    instagram: Instagram,
    appstore: AppStore,
    googleplay: GooglePlay,
    appstoreFR: AppStoreFrench,
    googleplayFR: GooglePlayFrench,
    undefined() {
      return <></>
    }
  }[icon]
}

export const getLink = (
  {
    slug,
    externalUrl,
    href,
    contentType,
    openInANewWindow,
    refType,
    target
  } = {},
  currentPage = ''
) => {
  let link
  const type = refType || contentType
  const newWindow = openInANewWindow || target === '_blank'

  switch (type) {
    case 'internalLink':
      link = {
        url: `${slug}`,
        path: slug,
        active: currentPage === slug
      }
      break
    case 'homePage':
      link = { url: '/', path: '/' }
      break
    case 'callToAction':
      link = { url: externalUrl || href, openInANewWindow: newWindow }
      break
    case 'page': {
      const url = `/${slug}`
      link = {
        url,
        path: '/[page]',
        active: currentPage === url
      }
    }
  }

  return link
}
