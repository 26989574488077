import Router from 'next/router'
import { destroyCookie, setCookie } from 'nookies'
import {
  coffeeSubsPlanCookieName,
  subscriptionURLPrefix,
  coffeeSubscriptionPlanIds,
  subscriptionBagIdHeader
} from '#constants'

const actionIdToSubscriptionPlanSku = [
  {
    planSku: coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN,
    contentfulActions: [
      'select-us-classic-coffee-plan',
      'select-us-classic-coffee-plan-2'
    ]
  },
  {
    planSku: coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN,
    contentfulActions: [
      'select-us-premium-coffee-plan',
      'select-us-premium-coffee-plan-2'
    ]
  }
]
const getSkuFromContentfulAction = action => {
  return actionIdToSubscriptionPlanSku.find(plan => {
    return plan.contentfulActions.includes(action)
  }).planSku
}

export const coffeeSubscriptionPlanSelection = event => {
  const actionId = event.currentTarget.getAttribute('data-action-id')
  const subscriptionSku = getSkuFromContentfulAction(actionId)
  setCookie(null, coffeeSubsPlanCookieName, subscriptionSku, {
    path: '/'
  })
  destroyCookie(null, subscriptionBagIdHeader, { path: '/' })
  return Router.push(`/${subscriptionURLPrefix}/signup`)
}
