import PropTypes from 'prop-types'
import {
  CampaignBannerContent,
  CampaignBannerHeading,
  CampaignBannerSubHeading,
  CampaignBannerWrapper,
  CampaignImageContainer,
  ActionContainer
} from './styles'
import { callToAction, image } from '../../../propTypes'
import { Action } from '../../call-to-action'
import { renderImage } from '#src/common/components/page-slots/image-utils'

const CampaignBanner = ({
  campaignImageSm,
  campaignImageMd,
  campaignImageLg,
  title,
  subTitle,
  action
}) => {
  return (
    <CampaignBannerWrapper>
      <CampaignImageContainer>
        {renderImage(campaignImageSm, campaignImageMd, campaignImageLg, title)}
      </CampaignImageContainer>
      <CampaignBannerContent>
        <CampaignBannerHeading level='h2' styleOverride='headingMd'>
          {title}
        </CampaignBannerHeading>
        {subTitle && (
          <CampaignBannerSubHeading level='h3' styleOverride='headingXs'>
            {subTitle}
          </CampaignBannerSubHeading>
        )}
        {action && (
          <ActionContainer>
            <Action {...action} />{' '}
          </ActionContainer>
        )}
      </CampaignBannerContent>
    </CampaignBannerWrapper>
  )
}

CampaignBanner.propTypes = {
  campaignImageSm: image.isRequired,
  campaignImageMd: image.isRequired,
  campaignImageLg: image.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  action: callToAction
}

export default CampaignBanner
