import { ReferFriend } from '#src/common/components/refer-friend'
import { useAsync } from 'react-use'
import { useState } from 'react'
import { getReferFriendFromClientSite } from '#src/common/lib/get-refer-friend'
import { MentionMeBanner } from '../styles'
import { useAppSelector } from '#src/state/redux-hooks'
import { envSelector } from '#src/state/env-state-slice'

const ThirdPartyPlaceholder = ({ locale }) => {
  const { mentionMeTag } = useAppSelector(envSelector)
  const [isThirdPartyPlaceholderEnabled, sethirdPartyPlaceholder] =
    useState(false)

  useAsync(async () => {
    const referFriend = await getReferFriendFromClientSite(locale)
    sethirdPartyPlaceholder(referFriend.enabled)
  }, [locale])

  if (!isThirdPartyPlaceholderEnabled || !mentionMeTag) {
    return <></>
  }

  return (
    <MentionMeBanner>
      <ReferFriend
        implementation='embed'
        locale={locale}
        situation='landingpage'
        type='referreroffer'
        enabled={isThirdPartyPlaceholderEnabled}
        host={mentionMeTag}
      />
    </MentionMeBanner>
  )
}

export default ThirdPartyPlaceholder
