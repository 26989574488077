import CustomNextImage from '#src/common/components/custom-image-component'

export const getImageSources = (imageSources, { src }) =>
  Object.entries(imageSources).reduce(
    (sources, [size, width]) => ({
      ...sources,
      [size]: `${src}?fm=jpg&fl=progressive&w=${width}`
    }),
    {}
  )

export const renderImage = (
  campaignImageSm,
  campaignImageMd,
  campaignImageLg,
  defaultAlt
) => {
  const CAMPAIGN_IMAGE_SOURCES_LG = { lg: 1280, xl: 1440 }
  const CAMPAIGN_IMAGE_SOURCES_MD = { md: 1024 }
  const CAMPAIGN_IMAGE_SOURCES_SM = { sm: 768 }
  const CAMPAIGN_IMAGE_SOURCES_XS = { xs: 640 }
  const imageSourcesLg = getImageSources(
    CAMPAIGN_IMAGE_SOURCES_LG,
    campaignImageLg
  )
  const imageSourcesMd = getImageSources(
    CAMPAIGN_IMAGE_SOURCES_MD,
    campaignImageLg
  )
  const imageSourcesSm = getImageSources(
    CAMPAIGN_IMAGE_SOURCES_SM,
    campaignImageMd
  )
  const imageSourcesXs = getImageSources(
    CAMPAIGN_IMAGE_SOURCES_XS,
    campaignImageSm
  )
  return (
    <CustomNextImage
      priority={true}
      sources={{
        ...imageSourcesLg,
        ...imageSourcesMd,
        ...imageSourcesSm,
        ...imageSourcesXs
      }}
      img={{ src: imageSourcesLg.xl }}
      alt={campaignImageLg.alt || defaultAlt}
    />
  )
}
