import PropTypes from 'prop-types'
import { settings as settingsType } from '@proptypes'
import { post } from '#lib/api/form'
import Accordion from './accordion'
import CampaignBanner from './campaign-banner'
import CarouselContainer from './carousel'
import CtaCollection from './cta-collection'
import CmsForm from '../form/CmsForm'
import Feature from './feature'
import Form from '../form'
import FeatureFullWidth from './feature-full-width'
import ProductList from './product-list'
import { getListProducts } from '../../../page/api'
import { FeatureSlot, FeatureHeroSlot, CampaignBannerSlot } from './styles'
import { FeatureGrid } from './feature-grid'
import { FeatureHero } from './feature-hero'
import { FeatureItem } from './feature-item'
import { SubscriptionCalculator } from './subscription-calculator'
import { OrderCustomInformation } from './order-custom-information'
import ThirdPartyPlaceholder from './third-party-placeholder'
import FeatureContainer from './feature-container'
import { ItemList } from './feature-item-list'
import Signature from './signature'

const PageSlots = ({
  isHomePage,
  settings,
  slots,
  locale,
  isPlattersLanding
}) => {
  if (!slots.length || !settings) {
    return null
  }

  return (
    <div className={isHomePage ? 'mb-0' : 'mb-20'}>
      {slots.map(({ contentType, id, identifier, ...slotProps }) => {
        switch (contentType) {
          case 'accordionContainer':
            return (
              <Accordion
                key={id}
                id={id}
                identifier={identifier}
                {...slotProps}
              />
            )
          case 'campaignBanner':
            return (
              <CampaignBannerSlot key={id}>
                <CampaignBanner {...slotProps} />
              </CampaignBannerSlot>
            )
          case 'carousel':
            return <CarouselContainer key={id} {...slotProps} />
          case 'ctaCollection':
            return <CtaCollection key={id} id={id} {...slotProps} />
          case 'feature':
            return (
              <FeatureSlot key={id} variant={slotProps.style}>
                <Feature
                  id={id}
                  socialNetworks={settings.socialNetworks}
                  {...slotProps}
                />
              </FeatureSlot>
            )
          case 'featureFullWidth':
            return (
              <FeatureSlot
                variant='featureFullWidth'
                key={id}
                className='mt-20'
              >
                <FeatureFullWidth {...slotProps} />
              </FeatureSlot>
            )
          case 'featureGrid':
            return <FeatureGrid key={id} {...slotProps} />
          case 'featureHero': {
            return (
              <FeatureHeroSlot key={id}>
                <FeatureHero {...slotProps} />
              </FeatureHeroSlot>
            )
          }
          case 'featureItem': {
            return (
              <FeatureSlot key={id}>
                <FeatureItem {...slotProps} />
              </FeatureSlot>
            )
          }
          case 'form':
            return identifier === 'Contact Us' ? (
              <CmsForm key={id} id={id} submitHandler={post} {...slotProps} />
            ) : (
              <Form key={id} id={id} submitHandler={post} {...slotProps} />
            )
          case 'productList':
            return (
              <ProductList
                key={id}
                getListProducts={getListProducts}
                placeholder={settings.placeholderImage}
                {...slotProps}
              />
            )
          case 'coffeeSubscriptionCalculator': {
            return (
              <FeatureSlot key={id}>
                <SubscriptionCalculator {...slotProps} />
              </FeatureSlot>
            )
          }
          case 'clubPretCalculator': {
            return (
              <FeatureSlot key={id}>
                <SubscriptionCalculator isClubPretFlow {...slotProps} />
              </FeatureSlot>
            )
          }
          case 'orderConfirmationSlot':
            return <OrderCustomInformation key={id} {...slotProps} />
          case 'thirdPartyPlaceholder': {
            return <ThirdPartyPlaceholder locale={locale} />
          }
          case 'featureContainer':
            return (
              <FeatureContainer
                key={id}
                {...slotProps}
                isPlattersLanding={isPlattersLanding}
              />
            )
          case 'featureItemList':
            return <ItemList key={id} {...slotProps} />
          case 'signature':
            return <Signature key={id} {...slotProps} />
          default:
            return <div key={id}>{contentType}</div>
        }
      })}
    </div>
  )
}

PageSlots.propTypes = {
  isHomePage: PropTypes.bool,
  settings: settingsType,
  slots: PropTypes.array,
  isPlattersLanding: PropTypes.bool
}

PageSlots.defaultProps = {
  isHomePage: false,
  slots: [],
  isPlattersLanding: false
}

export default PageSlots
