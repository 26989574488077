import styled from '@emotion/styled'
import { Card } from '@pretamanger/component-library'
import tw from 'twin.macro'

export const CardLink = styled('a')`
  ${tw`no-underline inline-block`}
`

export const ProductCard = styled(Card)`
  ${tw`cursor-pointer`}
`
