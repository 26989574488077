import { Action } from '#src/common/components/call-to-action'

// eslint-disable-next-line no-unused-vars
export const createCallToAction = (callToAction, { inlineButton } = {}) => {
  if (callToAction) {
    return <Action key={callToAction.id} {...callToAction} />
  }

  return null
}
