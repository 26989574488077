import { Action } from '#src/common/components/call-to-action'
import { RichText } from '#src/common/components/content'
import { translate } from '#src/common/components/translation'

const initialProductItem = locale => {
  const label =
    translate('cs.calculator.choosedrink.helptext', locale)?.value || ''
  return {
    id: 0,
    label: label,
    ariaLabel: label,
    value: label,
    price: 0
  }
}

const initialQtyItem = locale => {
  const label = translate('cs.calculator.howmany.helptext', locale)?.value || ''
  return {
    id: 0,
    label: label,
    ariaLabel: label,
    value: 0
  }
}

export const initialSavingsValue = {
  savings: [0, 0, 0],
  normal: [0, 0, 0],
  withCs: [0, 0, 0]
}

export const parseProductOptions = (products, locale) => {
  let productOptions = [initialProductItem(locale)]
  let id = 1
  products.map(product => {
    const optionData = {
      id: id,
      label: product.name,
      ariaLabel: product.name,
      value: product.name, //value must be unique else the label can't update, so price can't be put here//
      price: product.price
    }
    productOptions.push(optionData)
    id++
  })
  return productOptions
}

export const parseQtyOptions = locale => {
  const limit = 36
  let id = 1
  let options = [initialQtyItem(locale)]
  while (id < limit) {
    const data = {
      id: id,
      label: id,
      value: id,
      ariaLabel: String(id)
    }
    options.push(data)
    id++
  }
  return options
}

const currencySymbol = {
  'en-US': '$',
  'en-GB': '£',
  'fr-FR': '€'
}

export const getSavingsText = (savings, index, locale, isClubPretFlow) => {
  savings = parseFloat(savings).toLocaleString('en-US')
  const currency = currencySymbol[locale]
  if (savings < 0) {
    return `${translate('cs.calculator.unsuitable.text', locale)?.value}`
  } else {
    return `${
      translate('cs.calculator.couldsave.text', locale)?.value
    }${currency}${savings}${getDurationText(index, locale, isClubPretFlow)}!`
  }
}

export const getFormattedPrice = (
  savingsData,
  index,
  locale,
  isClubPretFlow
) => {
  const currency = currencySymbol[locale]
  const savings = parseFloat(savingsData[index]).toLocaleString('en-US')
  return `${currency}${savings}${getDurationText(
    index,
    locale,
    isClubPretFlow
  )}`
}

export const getDurationText = (index, locale, isClubPretFlow) => {
  const prefix = isClubPretFlow ? '/' : ' '
  switch (index) {
    case 1:
      return `${prefix}${String(
        translate('cs.calculator.permonth.text', locale)?.value || ''
      ).toLowerCase()}`
    case 2:
      return `${prefix}${String(
        translate('cs.calculator.peryear.text', locale)?.value || ''
      ).toLowerCase()}`
    default:
      return `${prefix}${String(
        translate('cs.calculator.perweek.text', locale)?.value || ''
      ).toLowerCase()}`
  }
}

export const parseTextField = field =>
  typeof field === 'string' ? field : <RichText text={field} withoutWrapper />

export const generateCta = actions => {
  if (!actions) {
    return []
  }
  return [
    actions.map(action => {
      const fields = action.fields
      const props = {
        id: action.sys.id,
        contentType: 'callToAction',
        identifier: fields.identifier,
        buttonAriaLabel: fields.buttonAriaLabel,
        buttonIcon: fields.buttonIcon,
        marketingId: fields.marketingId,
        eventOnClick: fields.eventOnClick,
        styleType: fields.buttonType,
        target: '_blank',
        href: fields.externalUrl
      }
      return <Action key={props.id} {...props} />
    })
  ]
}

export const getCalculationResults = (product, qty, planPrice) => {
  const totalPrice = parseFloat(product.price) * parseFloat(qty.value)
  const weekly = totalPrice - planPrice / 4.33
  const monthly = totalPrice * 4.33 - planPrice
  const yearly = monthly * 12
  return {
    savings: [weekly.toFixed(2), monthly.toFixed(2), yearly.toFixed(2)],
    normal: [
      totalPrice.toFixed(2),
      (totalPrice * 4.33).toFixed(2),
      (totalPrice * 4.33 * 12).toFixed(2)
    ],
    withCs: [(planPrice / 4.33).toFixed(2), planPrice, planPrice * 12]
  }
}
