import Image, { ImageProps, ImageLoaderProps } from 'next/image'

export function ContentfulImage(props: ImageProps) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image loader={contentfulLoader} {...props} />
}

function contentfulLoader({ src, width, quality }: ImageLoaderProps) {
  return `${src}?w=${width}&q=${quality || 75}&fm=jpg&fl=progressive`
}
